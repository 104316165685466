import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import { videoApi } from "./services/video.service";

export const store = configureStore({
  reducer: {
    user: userSlice,
    [videoApi.reducerPath]: videoApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(videoApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
