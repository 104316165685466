import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  username?: string;
  email?: string;
  isLoggedIn: boolean;
}

const initialState: UserState = {
  username: "",
  email: "",
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    mutate: (
      state,
      action: PayloadAction<{ username?: string; email?: string }>
    ) => {
      state.username = action.payload?.username || state.username;
      state.email = action.payload?.email || state.email;
    },
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
  },
});

export const { mutate, login, logout } = userSlice.actions;

export default userSlice.reducer;
