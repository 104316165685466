import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface Video {
  name: string;
  url: string;
}

export const videoApi = createApi({
  reducerPath: "videos",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    video: builder.query<Video[], void>({
      query: () => "/videos",
    }),
  }),
});

export const { useVideoQuery } = videoApi;
