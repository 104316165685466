import { useEtherBalance, useEthers } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import { Button } from "@mui/material";
// import { useSelector, useDispatch } from "react-redux";

import { config } from "./utils";
// import { RootState } from "./store";
// import { login, mutate } from "./features/user/userSlice";
// import { useVideoQuery } from "./services/video.service";

function App() {
  const { activateBrowserWallet, account, deactivate, chainId } = useEthers();
  const etherBalance = useEtherBalance(account);
  //   const user = useSelector((state: RootState) => state.user);
  //   const dispatch = useDispatch();
  //   const { data, error, isLoading, isFetching } = useVideoQuery();

  if (!chainId && !config.readOnlyUrls?.[chainId || 10000]) {
    return <p>Please use either Goerli testnet.</p>;
  }

  return (
    <div className="App">
      {account ? (
        <Button variant="contained" onClick={deactivate}>
          Disconnect
        </Button>
      ) : (
        <Button variant="contained" onClick={activateBrowserWallet}>
          Connect the wallet
        </Button>
      )}
      {etherBalance && (
        <div className="balance">
          Ether balance:
          <p className="bold">{formatEther(etherBalance)} ETH</p>
        </div>
      )}
    </div>
  );
}

export default App;
